<template>
  <v-container
    id="speaker-detail"
    fluid
    tag="section"
  >
    <base-material-card
      id="card-speaker-detail"
      color="primary"
    >
      <template v-slot:heading>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <div class="flex">
              <div>
                <img
                  :src="displayPhoto"
                  class="photo-speaker"
                />
              </div>
              <div class="pa-2 ml-4">
                <div>
                  <span class="display-3">
                    {{ speaker.sexe ? titreItems.find(item => item.sexe === speaker.sexe).titre : '' }} {{ speaker.prenom }} {{ speaker.nom }}
                  </span>
                  <span
                    v-if="speaker.societe"
                    class="mx-1 badge badge-societe"
                  >
                    {{ speaker.societe.raisonSociale }}
                  </span>
                  <span
                    v-else
                    class="mx-1 badge badge-no-societe"
                  >
                    Formateur interne
                  </span>
                </div>
                <div>
                  <span v-if="speaker.adresse && speaker.adresse.ville">
                    {{ speaker.adresse.ville }}
                  </span>
                  <span v-if="speaker.adresse && speaker.adresse.departement">
                    ({{ speaker.adresse.departement }})
                  </span>
                  <span v-if="speaker.adresse && speaker.dateNaissance">
                    •
                  </span>
                  <span v-if="speaker.dateNaissance">
                    {{ calculAge(speaker.dateNaissance) }} ans
                  </span>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="align-right"
          >
            <v-btn
              color="blue"
              rounded
              class="ma-2"
              :to="{ name: 'ModifierFormateur', params: { id: speaker.id } }"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              Modifier
            </v-btn>
            <v-btn
              color="red darken-1"
              rounded
              class="ma-2"
              @click="dialogDelete = true;"
            >
              <v-icon left>
                mdi-delete
              </v-icon>
              Supprimer
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-card-text class="body-1">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-row class="align-left">
              <v-col cols="12">
                <v-icon class="mx-2">mdi-phone</v-icon>
                <b class="mx-2">Téléphone :</b>
                <span>{{ speaker.telephone ? formatPhone(speaker.telephone) : '' }}</span>
              </v-col>
              <v-col cols="12">
                <v-icon class="mx-2">mdi-at</v-icon>
                <b class="mx-2">E-mail :</b>
                <span>{{ speaker.email }}</span>
              </v-col>
              <v-col cols="12">
                <p class="mb-1">
                  <v-icon class="mx-2">mdi-map-marker-outline</v-icon>
                  <b class="mx-2">Adresse :</b>
                </p>
                <p style="margin-left: 43px">
                  <span
                    v-html="speaker.adresse ? formatAdresse(speaker.adresse) : ''"
                    style="white-space: pre-line;"
                  >
                  </span>
                </p>
              </v-col>
              <v-col
                cols="12"
                v-if="speaker.societe"
              >
                <v-icon class="mx-2">mdi-office-building</v-icon>
                <b class="mx-2">Société :</b>
                <router-link :to="{ name: 'SousTraitantDetail', params: { id: speaker.societe.id } }">{{ speaker.societe.raisonSociale }}</router-link>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-row class="align-left">
              <v-col cols="12">
                <v-btn
                  color="#00838f"
                  rounded
                  class="ma-2"
                  @click="downloadCv()"
                  :disabled="downloadCvDisabled || !speaker.lienCV"
                >
                  <v-icon left>
                    mdi-download
                  </v-icon>
                  Télécharger le CV
                </v-btn>
              </v-col>
              <v-col cols="12">
                <p class="mb-1">
                  <v-icon class="mx-2">mdi-head-snowflake-outline</v-icon>
                  <b class="mx-2">Compétences :</b>
                </p>
                <v-chip-group
                  column
                >
                  <v-chip
                    v-for="competence in speaker.competences"
                    :key="competence.name"
                    @click="$router.push({ name: 'CoursDetail', params: { id: competence.id } })"
                  >
                    {{ competence.name }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </base-material-card>

    <base-material-card
      title="Sessions de formation"
      class="px-5 py-3"
      color="primary"
    >
      <v-data-table
        class="table-formations"
        :headers="headers"
        :items="formationsOfSpeaker"
        :items-per-page="10"
        :search="search"
        :sort-by="'dateDebut'"
        :sort-desc="false"
        @click:row="rowClick"
      >
        <template v-slot:[`item.etat`]="{ item }">
          <span v-html="displayEtat(item.etat)" />
        </template>
        <template v-slot:[`item.adresse`]="{ item }">
          {{ item.adresse.ville }} {{ item.adresse.departement ? '(' + item.adresse.departement + ')' : '' }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span v-html="displayType(item.type)" />
        </template>
      </v-data-table>
    </base-material-card>

    <!-- *** Avis **
    <base-material-card
      color="primary"
    >
      <div class="py-3"></div>
      <template v-slot:heading>
        <div class="display-1 font-weight-light">
          Avis : {{ note }} / 5 (sur {{ speaker.avis.length }} évaluations)
        </div>
      </template>
      <v-data-table
        class="table-reviews"
        :headers="headersAvis"
        :items="speaker.avis"
        :items-per-page="5"
        @click:row="rowClickAvis"
      >
        <template v-slot:[`item.stagiaire`]="{ item }">
          {{ item.stagiaire.nom }} {{ item.stagiaire.prenom }}
          <v-tooltip
            bottom
            v-if="item.note < 3.5"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-1"
                color="warning"
                v-bind="attrs"
                v-on="on"
              >
                mdi-alert-circle
              </v-icon>
            </template>
            <span>Stagiaire insatisfait</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.stagiaire.societe`]="{ item }">
          {{ item.stagiaire.societe ? item.stagiaire.societe.raisonSociale : '' }}
        </template>
        <template v-slot:[`item.note`]="{ item }">
            <v-rating
              class="note-avis"
              color="green"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              half-increments
              hover
              length="5"
              readonly
              size="17"
              :value="item.note"
            />
        </template>
      </v-data-table>
    </base-material-card>
    -->
    <template>
      <v-dialog
        v-model="dialogDelete"
        max-width="600px"
      >
        <v-card>
          <v-card-title
            class="headline"
          >
            Confirmer la suppression de ce formateur ?
          </v-card-title>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="gray"
              @click="dialogSubmit = false"
            >
              Annuler
            </v-btn>
            <v-btn
              color="primary"
              @click="deleteSpeaker()"
            >
              Confirmer la suppression
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data () {
      return {
        dialogDelete: false,
        downloadCvDisabled: false,
        sendCharteDisabled: false,
        search: '',
        speaker: {
          adresse: {},
          avis: [],
          competences: [],
        },
        note: '',
        itemsSpeaker: [],
        itemsSociete: [],
        formations: [],
        formationsOfSpeaker: [],
        displayPhoto: null,
        headers: [
          {
            sortable: true,
            text: 'Intitulé',
            value: 'intitule',
          },
          {
            sortable: true,
            text: 'État',
            value: 'etat',
          },
          {
            sortable: false,
            text: 'Type',
            value: 'type',
          },
          {
            sortable: true,
            text: 'Date début',
            value: 'dateDebutFormatted',
          },
          {
            sortable: false,
            text: 'Date fin',
            value: 'dateFinFormatted',
          },
          {
            sortable: false,
            text: 'Lieu',
            value: 'adresse',
          },
          {
            sortable: false,
            text: 'Stagiaires',
            value: 'nbStagiaires',
          },
        ],
        headersAvis: [
          {
            sortable: false,
            text: 'Stagiaire',
            value: 'stagiaire',
          },
          {
            sortable: false,
            text: 'Formation',
            value: 'cours.reference',
          },
          {
            sortable: true,
            text: 'Date',
            value: 'dateFormatted',
          },
          {
            sortable: true,
            text: 'Note',
            value: 'note',
          },
        ],
        titreItems: [
          {
            titre: 'M.',
            sexe: 'm',
          },
          {
            titre: 'Mme',
            sexe: 'f',
          },
        ],
      }
    },

    created () {
      this.axios.get('/intervenants/' + this.$route.params.id)
        .then((res) => {
          this.speaker = res.data;

          let n = 0;
          this.speaker.avis.forEach((a) => {
            n += a.note;
          });
          this.note = n / this.speaker.avis.length;

          this.itemsSpeaker = [
            {
              icon: 'mdi-phone',
              title: 'Téléphone',
              value: this.speaker.telephone ? this.formatPhone(this.speaker.telephone) : '',
            },
            {
              icon: 'mdi-at',
              title: 'E-mail',
              value: this.speaker.email ?? '',
            },
            {
              icon: 'mdi-map-marker-outline',
              title: 'Adresse',
              value: this.speaker.adresse ? this.formatAdresse(this.speaker.adresse) : '',
            },
          ];

          if (this.speaker.societe) {
            this.itemsSociete = [
              {
                icon: 'mdi-office-building',
                title: 'Raison Sociale',
                value: this.speaker.societe.raisonSociale ?? '',
              },
              {
                icon: 'mdi-barcode-scan',
                title: 'Forme juridique',
                value: this.speaker.societe.formeJuridique ?? '',
              },
              {
                icon: 'mdi-phone',
                title: 'Téléphone',
                value: this.speaker.societe.telephone ? this.formatPhone(this.speaker.societe.telephone) : '',
              },
              {
                icon: 'mdi-at',
                title: 'E-mail',
                value: this.speaker.societe.email ?? '',
              },
              {
                icon: 'mdi-map-marker-outline',
                title: 'Adresse',
                value: this.speaker.societe.adresse ? this.formatAdresse(this.speaker.societe.adresse) : '',
              },
              {
                icon: 'mdi-ticket-confirmation-outline',
                title: 'N° SIREN',
                value: this.speaker.societe.siren ?? '',
              },
              {
                icon: 'mdi-ticket-confirmation-outline',
                title: 'N° SIRET',
                value: this.speaker.societe.siret ?? '',
              },
              {
                icon: 'mdi-ticket-confirmation-outline',
                title: 'N° TVA Intra.',
                value: this.speaker.societe.tvaIntra ?? '',
              },
              {
                icon: 'mdi-file-document-outline',
                title: 'Attestation de vigilance',
                value: this.speaker.societe.attestationVigilanceRecue ? 'Reçue' : 'Non reçue',
              },
              {
                icon: 'mdi-bank',
                title: 'IBAN',
                value: this.speaker.societe.iban ?? '',
              },
              {
                icon: 'mdi-bank',
                title: 'SWIFT',
                value: this.speaker.societe.swift ?? '',
              },
            ];
          }

          if (this.speaker.photo) {
            this.axios.get('/intervenants/' + this.speaker.id + '/getPhoto/90', { responseType: 'arraybuffer' })
              .then(res => {
                this.displayPhoto = 'data:image/png;base64,' + Buffer.from(res.data, 'binary').toString('base64');
              })
          } else {
            this.displayPhoto = this.getAvatar(this.speaker);
          }
        })
        .then((res) => {
          this.axios.get('/intervenants/' + this.$route.params.id + '/allFormations')
            .then((res) => {
              this.formationsOfSpeaker = [...res.data];

              this.formationsOfSpeaker.forEach(formation => {
                formation.dateDebutFormatted = new Date(formation.dateDebut).toLocaleString('fr-FR').substring(0, 10);
                formation.dateFinFormatted = new Date(formation.dateFin).toLocaleString('fr-FR').substring(0, 10);
                if (formation.distanciel) {
                  formation.adresse = { ville: 'Visio' };
                }
              });
            });
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération du formateur', type: 'warn' });
        });
    },

    methods: {

      deleteSpeaker() {
        this.axios.delete('/intervenants/' + this.speaker.id)
          .then((res) => {
            this.setSnack({ text: 'Le formateur a bien été supprimé', type: 'success' });
            this.$router.push({ name: 'Formateurs' })
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de la suppression du formateur', type: 'warn' });
          });
        this.dialogDelete = false;
      },

      calculAge(dateNaissance) {
        let age = new Date().getFullYear() - new Date(dateNaissance).getFullYear();
        const m = new Date().getMonth() - new Date(dateNaissance).getMonth();
        if (m < 0 || (m === 0 && new Date().getDate() < new Date(dateNaissance).getDate())) {
          age--;
        }
        return age;
      },

      downloadCv() {
        this.downloadCvDisabled = true;
        this.axios.get('/intervenants/' + this.speaker.id + '/downloadCv', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'CV_' + this.speaker.nom + '_' + this.speaker.prenom + '.pdf');
            document.body.appendChild(link);
            link.click();
            this.downloadCvDisabled = false;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement du CV du formateur', type: 'warn' });
            this.downloadCvDisabled = false;
          });
      },

      sendCharte() {
        this.sendCharteDisabled = true;
        this.axios.post('/intervenants/' + this.speaker.id + '/sendCharte')
          .then((res) => {
            this.setSnack({ text: 'Charte qualité envoyée au formateur', type: 'success' });
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de l\'envoi de la charte qualité', type: 'warn' });
            this.sendCharteDisabled = false;
          });
      },

      displayEtat(etat) {
        switch (etat) {
          case 'OUVERT':
            return '<span id="formation-etat" class="badge-chips badge-blue">Ouverte</span>';
          case 'CONFIRME':
            return '<span id="formation-etat" class="badge-chips badge-green">Confirmée</span>';
          case 'ENCOURS':
            return '<span id="formation-etat" class="badge-chips badge-indigo">En cours</span>';
          case 'TERMINE':
            return '<span id="formation-etat" class="badge-chips badge-purple">Terminée</span>';
          case 'FERME':
            return '<span id="formation-etat" class="badge-chips badge-red">Fermée</span>';
          case 'ANNULE':
            return '<span id="formation-etat" class="badge-chips badge-red">Annulée</span>';
        }
      },

      displayType(type) {
        switch (type) {
          case 'INTER':
            return '<span class="badge-chips-small badge-blue-outline">Inter</span>';
          case 'INTRA':
            return '<span class="badge-chips-small badge-yellow-outline">Intra</span>';
          case 'SSTRT':
            return '<span class="badge-chips-small badge-gray-outline">Sous-traitée</span>';
        }
      },

      rowClick(item, row) {
        this.$router.push({ name: 'FormationDetail', params: { id: item.id } });
      },

      rowClickAvis(item, row) {
        this.$router.push({ name: 'ReviewDetail', params: { id: item.id } });
      },

      formatPhone(number) {
        return number.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
      },

      getAvatar(speaker) {
        return speaker.sexe === 'm' ? require('@/assets/avatar-man-mini.png') : require('@/assets/avatar-woman-mini.png');
      },

      formatAdresse(adresse) {
        let formattedAdresse = '';
        formattedAdresse += adresse.adressePostale ? adresse.adressePostale + '<br>' : '';
        formattedAdresse += adresse.codePostal ? adresse.codePostal + ' ' : '';
        formattedAdresse += adresse.ville ?? '';
        formattedAdresse += adresse.pays ? ', ' + adresse.pays + ' ' : '';
        return formattedAdresse;
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>
  #speaker-detail >>> .flex {
    display: flex;
  }

  #speaker-detail >>> .align-right {
    text-align: right;
  }

  #speaker-detail >>> .text-center {
    text-align: center;
  }

  #speaker-detail >>> .photo-speaker {
    border-radius: 50%;
    height: 90px;
    width: 90px;
  }

  #speaker-detail >>> .table-formations tbody td:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  #speaker-detail >>> .table-formations tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    transition: background-color 0.1s;
  }

  #speaker-detail >>> .table-formations tbody tr:not(.v-row-group__header):hover {
    background-color: #f8f8f8;
    font-weight: 400 !important;
  }

  #speaker-detail >>> .badge-chips-small {
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 0.7rem;
    font-weight: 700;
  }

  #speaker-detail >>> .table-reviews tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    transition: background-color 0.1s;
  }

  #speaker-detail >>> .table-reviews tbody tr:not(.v-row-group__header):hover {
    background-color: #f8f8f8;
    font-weight: 400 !important;
  }

  #speaker-detail >>> .note-avis .v-icon.mdi-star,
  #speaker-detail >>> .note-avis .v-icon.mdi-star-half-full,
  #speaker-detail >>> .note-avis .v-icon.mdi-star-outline {
    padding: 0;
    color: #4caf51 !important;
  }

  #speaker-detail >>> .note-avis .v-icon.mdi-help-circle {
    padding: 0;
    color: #adadad !important;
  }

  #speaker-detail >>> .table-reviews tbody td:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }

  #speaker-detail >>> .badge-chips {
    padding: 3px 6px;
    font-weight: 600;
    border-radius: 4px;
    font-size: 0.7rem;
  }

  #speaker-detail >>> .badge-blue {
    background-color: #1967c026;
    color: #135095;
  }

  #speaker-detail >>> .badge-green {
    background-color: #00968826;
    color: #02643f;
  }

  #speaker-detail >>> .badge-indigo {
    background-color: #3f51b526;
    color: #2e3c8a;
  }

  #speaker-detail >>> .badge-purple {
    background-color: #9c28b026;
    color: #7b1f8a;
  }

  #speaker-detail >>> .badge-red {
    background-color: #e9316226;
    color: #bb274f;
  }

  #speaker-detail >>> .badge-blue-outline {
    border: solid 1px #4b70ff;
    color: #4b70ff;
  }

  #speaker-detail >>> .badge-yellow-outline {
    border: solid 1px #c95017;
    color: #c95017;
  }

  #speaker-detail >>> .badge-gray-outline {
    border: solid 1px #797b86;
    color: #797b86;
  }

  #speaker-detail >>> .badge {
    font-size: 0.7rem;
    border-radius: 4px;
    padding: 4px 6px;
    font-weight: 500;
    vertical-align: super;
  }

  #speaker-detail >>> .badge-societe {
    background-color: #989696cc;
    color: #fff;
  }

  #speaker-detail >>> .badge-no-societe {
    background-color: #382680;
    color: #fff;
  }
</style>
